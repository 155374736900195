import React, { useEffect } from 'react'
import lassfarLogo from 'images/logo/lassfar-logo-x2.svg';
import { parallaxContentWithCursor } from '../Helpers/functions';

const AboutShape = () => {

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('mousemove', (e) => {
        parallaxContentWithCursor(e, '.about__shapeLogo', true);
      });
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('mousemove', parallaxContentWithCursor);
      }
    }
  }, []);

  return (
    <div className="about__shapeSection">
      <img className="about__shapeLogo" src={lassfarLogo} alt="logo aymane lassfar" data-speed="-2" /> 
    </div>
  )
}

export default AboutShape

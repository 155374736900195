import React from 'react'
import SEO from 'components/seo'
import Layout from 'components/layout'
import NextBtn from 'components/ui/next-btn'
import AboutMe from 'components/about/about-me'
import 'scss/about.scss'


const About = () => {
  return (
    <Layout pageTitle="About">
      <SEO
        title="ABOUT"
        image="/img/about-preview.png"
        description="I'm a Web Developer, I'm passionate about working/learning new web technologies."
      />
      <AboutMe />
      <NextBtn
        domClassesToHide=".header,.about,.next__btn"
        nextBtnTitle="WORKS"
        nextPageLink="/works" />
    </Layout>
  )
}

export default About

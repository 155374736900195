import React, { useEffect } from 'react'
import { showPageWithTransition } from 'Helpers/functions'
import 'scss/about.scss'
import AboutShape from './about-shape';


const AboutMe = () => {
  
  useEffect(() => {
    showPageWithTransition();
  }, []);

  return (
    <div className="about">
      <section className="about__section">
        <article className="about__descSection">
          <h1 className="about__title">
            ABOUT ME
          </h1>
          <p className="about__desc">
            I'm a passionate web developer, with 4 years of work experience, individually or as a part of a team, I like web developement challenging and envolving projects, I take my work seriously and ensure my skills are kept up to date with changing industry.
          </p>
          <p className="about__desc">
            I <span>💖</span> ambitious work, because it's the core of motivation. Thirsty to give the most.
            I learn <span>⚡🚀</span>.
          </p>
        </article>
      </section>

      <AboutShape />
    </div>
  )
}

export default AboutMe
